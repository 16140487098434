import {createEffect, createEvent, createStore, restore} from "effector";
import {
  type CreateWarehouseRequestType,
  type WarehouseType,
} from "@shared/api/warehouses/types";
import {getWarehousesApi} from "@shared/api/warehouses/warehouses";
import {createWarehousesApi} from "@shared/api/warehouses/createWarehouse";

export const getWarehouses = createEvent();
export const getWarehousesFx = createEffect(getWarehousesApi);

export const createWarehouse = createEvent<CreateWarehouseRequestType>();
export const createWarehouseFx = createEffect(createWarehousesApi);
export const $warehousesStore = createStore<WarehouseType[] | null>(null);

export const $createWarehouseError = restore(createWarehouseFx.failData, null);
export const $getWarehousesError = restore(getWarehousesFx.failData, null);
