import {baseApi} from "@shared/api/baseApi";
import {type components} from "@shared/api/schemas/api";

export type SignInRequestType = components["schemas"]["LoginRequest"];
export type SignInResponseType = components["schemas"]["Auth"];

export const signInApi = async ({
  email,
  password,
}: SignInRequestType): Promise<SignInResponseType> => {
  return await baseApi
    .post("v1/auth/login", {
      body: JSON.stringify({
        email,
        password,
      }),
      headers: {"Content-type": "application/json"},
    })
    .json();
};
