import {
  $accessToken,
  $isAuthorized,
  $signInError,
  $signUpError,
  $user,
  signInFx,
  signUpFx,
} from "@services/Auth/Units";
import "./Methods/Post/SignUp";
import "./Methods/Post/SignIn";
export const signUpSelectors = {
  isError: $signUpError,
  isLoading: signUpFx.pending,
};

export const signInSelectors = {
  isError: $signInError,
  isLoading: signInFx.pending,
};

export const authSelectors = {
  isAuth: $isAuthorized,
  token: $accessToken,
  user: $user,
};
