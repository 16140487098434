import YandexLogo from "@shared/assets/images/MarketplaceYandexLogo.svg?react";
import OzonLogo from "@shared/assets/images/Ozon.svg?react";
import MegamarketLogo from "@shared/assets/images/MegaMarket.svg?react";
import WildberriesLogo from "@shared/assets/images/Wildberries.svg?react";
import React from "react";

export const shops = [
  {
    logo: YandexLogo,
    text: "Яндекс.Маркет",
  },
  {logo: OzonLogo, text: "Ozon"},
  {logo: MegamarketLogo, text: "МегаМаркет"},
  {logo: WildberriesLogo, text: "Wildberries"},
];
