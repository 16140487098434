import style from "./auth.module.scss";
import React from "react";
import {Button, ImageWrapper} from "@shared/ui";
import Logo from "@shared/assets/images/Logo.png";
import {SignUpForm} from "@features/auth";

export const SignUpPage = (): React.ReactNode => {
  return (
    <div className={style.wrapper}>
      <div className={style.content}>
        <div className={style.header}>
          <ImageWrapper alt="logo" height={48} src={Logo} width={48} />
          <span className={style.logoText}>ecomes</span>
        </div>
        <SignUpForm />
        <Button className={style.button} to="/login">
          У меня уже есть аккаунт
        </Button>
      </div>
    </div>
  );
};
