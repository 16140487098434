import style from "./contents.module.scss";
import React from "react";
import {type ContentsProps} from "./types";
export const Contents = ({list}: ContentsProps): React.ReactNode => (
  <ul className={style.contents}>
    {list.map(({key, value}) => (
      <li key={key}>
        <div>
          <span>{key}</span>
        </div>
        <div>{value}</div>
      </li>
    ))}
  </ul>
);
