import style from "./boxedNumber.module.scss";
import React from "react";

interface BoxedNumberProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}
export const BoxedNumber = ({
  children,
  ...rest
}: BoxedNumberProps): React.ReactNode => (
  <div className={style.wrapper} {...rest}>
    {children}
  </div>
);
