import style from "@shared/ui/Accordion/accordion.module.scss";
import Chevron from "@shared/assets/images/Chevron.svg?react";
import React, {useState} from "react";
import cn from "classnames";
import {type AccordionProps} from "./types";

export const Accordion = ({
  title,
  children,
}: AccordionProps): React.ReactNode => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggle = (): void => {
    setIsExpanded(prev => !prev);
  };

  return (
    <div className={style.wrapper}>
      <div className={style.header} onClick={toggle}>
        <span className={style.title}>{title}</span>
        <Chevron className={cn(style.icon, {[style.rotated]: isExpanded})} />
      </div>
      <div className={cn(style.content, {[style.expanded]: isExpanded})}>
        {children}
      </div>
    </div>
  );
};
