import React from "react";
import Select, {type Props} from "react-select";

import {customStyles} from "./styles";

interface CustomSelectProps extends Props {
  variant?: string;
}

export const CustomSelect = ({
  options,
  variant,
  ...rest
}: CustomSelectProps): React.ReactNode => {
  const selectStyles = {
    ...customStyles,
  };

  return <Select options={options} styles={selectStyles} {...rest} />;
};
