import classes from "./notify.module.scss";
import {BodyVariants, Typography} from "@shared/ui";
import React, {type ReactNode} from "react";
import {type NotificationProps} from "@shared/ui/Notification/types";

export const Notification = ({
  title,
  children,
}: NotificationProps): ReactNode => {
  return (
    <div className={classes.wrapper}>
      <Typography className={classes.title} v={BodyVariants.SECONDARYM}>
        {title}
      </Typography>
      <Typography className={classes.text} v={BodyVariants.SECONDARY}>
        {children}
      </Typography>
    </div>
  );
};
