import {createEvent, createStore, sample} from "effector";
import {useUnit} from "effector-react";

type Place = "modal" | "root" | null;

interface BackgroundStore {
  isActive: boolean;
  placement: Place;
}

export const toggleBackground = createEvent<Place>();
export const offBackground = createEvent();

const $backgroundStore = createStore<BackgroundStore>({
  isActive: false,
  placement: null,
});

// Toggle
sample({
  clock: toggleBackground,
  fn: (store, place) => ({
    isActive: !store.isActive,
    placement: store.placement ? null : place,
  }),
  source: $backgroundStore,
  target: $backgroundStore,
});

// Off
sample({
  clock: offBackground,
  fn: () => ({isActive: false, placement: null}),
  target: $backgroundStore,
});

export const useBackground = (): BackgroundStore => {
  const {isActive, placement} = useUnit($backgroundStore);
  return {isActive, placement};
};
