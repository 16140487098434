import cn from "classnames";
import React from "react";

import style from "./iconButton.module.scss";
import {type IconButtonProps} from "./types";
/**
 * IconButton component.
 *
 * @component
 * @example
 * // Example usage of IconButton
 * <IconButton icon={<Icon />} onClick={handleClick}>
 *   Button Text
 * </IconButton>
 *
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.icon - The icon element to be rendered.
 * @param {React.ReactNode} props.children - The text or content of the button.
 * @param {Function} props.onClick - The click event handler for the button.
 * @returns {JSX.Element} The rendered IconButton component.
 */

export const IconButton = ({
  icon,
  children,
  onClick,
  className,
}: IconButtonProps): React.ReactNode => (
  <button className={cn(style.iconButton, className)} onClick={onClick}>
    {Boolean(children) && <span className={style.text}>{children}</span>}
    {icon}
  </button>
);
