import {type ReactNode} from "react";

export enum Statuses {
  Success = "success",
  Error = "error",
}

export interface TextWithStatusProps {
  children: ReactNode;
  status: Statuses;
  indicatorAlign?: "left" | "right";
}
