import React from "react";

import ContentLoader from "react-content-loader";

export const Loader = (): React.ReactNode => (
  <ContentLoader
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    height={630}
    speed={2}
    style={{width: "100%"}}
    animate>
    <rect height="50" ry="8" width="100%" />
    <rect height="50" ry="8" width="100%" y={90} />
    <rect height="50" ry="8" width="100%" y={180} />
    <rect height="50" ry="8" width="100%" y={270} />
    <rect height="50" ry="8" width="100%" y={360} />
    <rect height="50" ry="8" width="100%" y={450} />
    <rect height="50" ry="8" width="100%" y={560} />
  </ContentLoader>
);
