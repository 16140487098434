import style from "./background.module.scss";
import React from "react";
import {useBackground} from "@shared/hooks/useBackground";
import {AnimatePresence, motion} from "framer-motion";
import {type BackgroundProps} from "./types";

export const Background = ({
  top,
  currentPlacement,
}: BackgroundProps): React.ReactNode => {
  const {placement, isActive} = useBackground();

  return (
    <AnimatePresence>
      {isActive && currentPlacement === placement && (
        <motion.div
          animate={{opacity: 1}}
          className={style.background}
          exit={{opacity: 0}}
          initial={{opacity: 0}}
          style={{top}}
          transition={{duration: 0.15}}
        />
      )}
    </AnimatePresence>
  );
};
