import {sample} from "effector";
import {
  $goodsStore,
  getGoods,
  getGoodsByNameOrSku,
  getGoodsByNameOrSkuFx,
  getGoodsFx,
} from "@services/Goods/Units";

sample({clock: getGoods, target: getGoodsFx});
sample({clock: getGoodsFx.doneData, target: $goodsStore});

sample({clock: getGoodsByNameOrSku, target: getGoodsByNameOrSkuFx});
sample({clock: getGoodsByNameOrSkuFx.doneData, target: $goodsStore});
