import style from "../auth.module.scss";
import {BodyVariants, Button, Input, Typography} from "@shared/ui";
import React from "react";
import {type SubmitHandler, useForm} from "react-hook-form";
import {useFormErrorNotifications} from "@shared/hooks/useFormErrorNotifications";
import {yupResolver} from "@hookform/resolvers/yup";
import {schema, type SignInFormType} from "@features/auth/signIn/model";
import {useUnit} from "effector-react";
import {signIn, signInSelectors} from "@services/Auth";

export const SignInForm = (): React.ReactNode => {
  const [isLoading] = useUnit([signInSelectors.isLoading]);

  const {
    register,
    formState: {errors},
    handleSubmit,
  } = useForm<SignInFormType>({
    resolver: yupResolver(schema),
  });

  useFormErrorNotifications(errors);

  const onSubmit: SubmitHandler<SignInFormType> = ({email, password}) => {
    signIn({email, password});
  };

  return (
    <form className={style.form} onSubmit={handleSubmit(onSubmit)}>
      <div className={style.formHeader}>
        <Typography v={BodyVariants.M}>Вход</Typography>
      </div>
      <div className={style.formFields}>
        <Input
          className={style.formInput}
          colorScheme="dark"
          isError={Boolean(errors.email)}
          label="E-mail"
          {...register("email")}
        />
        <Input
          className={style.formInput}
          colorScheme="dark"
          isError={Boolean(errors.password)}
          label="Пароль"
          type="password"
          {...register("password")}
        />
      </div>
      <div className={style.formFooter}>
        <Button to="/restore" variant="outline">
          Восстановить пароль
        </Button>
        <Button colorScheme="dark" disabled={isLoading} type="submit">
          Войти
        </Button>
      </div>
    </form>
  );
};
