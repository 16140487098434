import {ModalCenter} from "@shared/ui/ModalCenter/ModalCenter";
import {WarehousesForm} from "@features/warehouses/form";
import React, {type ReactNode} from "react";

export const WarehouseAddModal = (): ReactNode => {
  return (
    <ModalCenter title="Добавление склада">
      <WarehousesForm />
    </ModalCenter>
  );
};
