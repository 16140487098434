/// <reference types="vite-plugin-svgr/client" />
import MarketplaceYandexLogo from "@shared/assets/images/MarketplaceYandexLogo.svg?react";
import Box from "@shared/assets/images/ButtonBox.svg?react";
import HourGlass from "@shared/assets/images/HourGlass.svg?react";
import {Modal} from "@shared/ui/Modal/Modal";
import {Button, IconButton, TextWithIcon} from "@shared/ui";
import React from "react";
import ArrowBack from "@shared/assets/images/ArrowBack.svg?react";
import style from "./ordersInfoModal.module.scss";
import {useNavigate, useParams} from "react-router-dom";
import cn from "classnames";
import {OrderByPlaces} from "@widgets/Orders/ui/OrderByPlaces/ui/OrderByPlaces";
import {orders} from "@widgets/Orders/config/mock";
import {Accordion} from "@shared/ui/Accordion/Accordion";
import {Contents} from "@shared/ui/Contents/Contents";
import {list} from "@widgets/OrdersInfoModal/config/contentsMockData";

export const OrdersInfoModal = (): React.ReactNode => {
  const navigate = useNavigate();
  const {id} = useParams();

  const navigateBack = (): void => {
    navigate(-1);
  };

  return (
    <Modal>
      <div className={style.header}>
        <div className={style.headerLeftSlot}>
          <Button className={style.back} onClick={navigateBack}>
            <ArrowBack /> Назад
          </Button>
          <span className={style.orderNumber}>№{id}</span>
        </div>
        <div className={style.headerRightSlot}>
          <Button className={style.cancel}>Отменить заказ</Button>
        </div>
      </div>
      <div className={style.content}>
        <div className={style.status}>
          <div className={style.statusHeader}>
            <div className={style.statusText}>Доставка в ПВЗ</div>
            <div>
              <TextWithIcon icon={<MarketplaceYandexLogo />} text="SkyTeck" />
            </div>
            <div className={style.acceptDate}>
              Принят 29 сентября 2023, 15:00
            </div>
            <IconButton icon={<Box />}>Ярлыки</IconButton>
          </div>
          {/* TODO вынести в компонент shared/stages или в feature */}
          <div className={style.stages}>
            <div className={cn(style.stage, style.done)}>
              <div className={style.stageIconWrapper}>
                <HourGlass className={style.stageIcon} />
              </div>
              <div className={style.stageInfo}>
                <div className={style.stageText}>Подтверждение заказа</div>
                <div className={style.stageTextSecondary}>Осталось 24 часа</div>
              </div>
            </div>
            <div className={cn(style.stage, style.active)}>
              <div className={cn(style.stageIconWrapper, style.active)}>
                <HourGlass className={style.stageIcon} />
              </div>
              <div className={style.stageInfo}>
                <div className={style.stageText}>Подтверждение заказа</div>
                <div className={style.stageTextSecondary}>
                  <Button>Посмотреть отгрузку</Button>
                </div>
              </div>
            </div>
            <div className={style.stage}>
              <div className={style.stageIconWrapper}>
                <HourGlass className={style.stageIcon} />
              </div>
              <div className={style.stageInfo}>
                <div className={style.stageText}>Подтверждение заказа</div>
                <div className={style.stageTextSecondary}>Осталось 24 часа</div>
              </div>
            </div>
          </div>
        </div>
        <div className={style.main}>
          <div className={style.orderByPlacesWrapper}>
            <OrderByPlaces
              backgroundPlacement="modal"
              places={orders[0].places}
            />
          </div>
          <Accordion title="Детали">
            <Contents list={list} />
          </Accordion>
          <Accordion title="Доставка">
            <Contents list={list} />
          </Accordion>
          <Accordion title="История заказа">
            <Contents list={list} />
          </Accordion>
        </div>
      </div>
    </Modal>
  );
};
