import {useEffect, useState} from "react";
import {type Coordinates, type Position} from "@shared/ui/Dropdown/types";

export const useGetCoordinates = (
  ref: React.ForwardedRef<HTMLDivElement>,
  position?: Position,
): {coordinates: Coordinates | null} => {
  const [coordinates, setCoordinates] = useState<Coordinates | null>(null);

  const handleResize = (): void => {
    if (typeof ref !== "function" && ref?.current) {
      const {bottom, right, left} = ref.current.getBoundingClientRect();
      setCoordinates({
        left: position?.horizontal === "right" ? `${right}px` : `${left}px`,
        top: `${bottom}px`,
      });
    }
  };

  useEffect(() => {
    handleResize(); // Вызвать handleResize только после полной отрисовки компонента

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return {coordinates};
};
