import {useEffect} from "react";
import * as Sentry from "@sentry/react";

import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

function maskSensitiveData(event: Sentry.ErrorEvent): Sentry.ErrorEvent {
  if (event.extra?.body as string) {
    // Проверяем, что данные являются JSON
    try {
      const data = JSON.parse(event.extra?.body as string);
      if (data.password) {
        data.password = "******"; // Замаскируем пароль
      }

      if (event.extra) {
        event.extra.body = JSON.stringify(data);
      }
    } catch (e) {
      // Если данные не являются JSON, ничего не делаем
    }
  }

  return event;
}

Sentry.init({
  attachStacktrace: true,
  autoSessionTracking: true,
  // beforeSend: maskSensitiveData,
  dsn: "https://5851f3b6bcec4fde8871d829f8773feb@o294308.ingest.us.sentry.io/4504803481681920",
  enableTracing: true,
  enabled: true,
  environment: import.meta.env.MODE,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      createRoutesFromChildren,
      matchRoutes,
      useEffect,
      useLocation,
      useNavigationType,
    }),
  ],
  replaysSessionSampleRate: 0.1,
  sendDefaultPii: true,
  tracePropagationTargets: [import.meta.env.VITE_BASE_URL],
  tracesSampleRate: 1.0,
});
