import React from "react";
import style from "./modal.module.scss";
import {Background} from "@shared/ui/Background/Background";
import {type ModalProps} from "./types";
import {RenderAnimation} from "@shared/ui/Animations/MountAnimate";
import {ReactPortal} from "@shared/ui/Portal/ReactPortal";
import {useClickOutside} from "@shared/hooks";
import {useNavigate} from "react-router-dom";
import {useCallNoMoreThenN} from "@shared/hooks/useCallNoMoreThenN";
import {offBackground} from "@shared/hooks/useBackground";
import cn from "classnames";

const Modal = (props: ModalProps): React.ReactNode => {
  const {children, onClose, avoidClass, className, ...rest} = props;
  const navigate = useNavigate();
  const handleClose = (): void => {
    navigate(-1);
    onClose?.();
  };

  const handleCloseWrapped = useCallNoMoreThenN(handleClose, 1);

  const ref = useClickOutside<HTMLDivElement>(
    [handleCloseWrapped, offBackground],
    avoidClass,
  );

  return (
    <ReactPortal wrapperId="modalContainer">
      <RenderAnimation>
        <div
          className={cn(style.modal, className)}
          id="modalContent"
          ref={ref}
          {...rest}>
          <Background currentPlacement="modal" top="15%" />
          {children}
        </div>
      </RenderAnimation>
    </ReactPortal>
  );
};

export {Modal};
