const dropIn = {
  exit: {
    opacity: 0,
    y: "40vh",
  },
  hidden: {
    opacity: 0,
    y: "40vh",
  },
  visible: {
    opacity: 1,
    transition: {
      damping: 25,
      ease: [0.17, 0.67, 0.83, 1],
      type: "Tween",
    },
    y: "0",
  },
};

const opacity = {
  exit: {
    opacity: 0,
  },
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
};

export {opacity, dropIn};
