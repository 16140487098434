import "./sentry";
import {createRoot} from "react-dom/client";
import App from "@app/App";
import React, {StrictMode} from "react";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <StrictMode>
    <App />
    <ToastContainer
      autoClose={3000}
      closeOnClick={false}
      draggable={false}
      hideProgressBar={true}
      newestOnTop={true}
      pauseOnFocusLoss={false}
      pauseOnHover={false}
    />
  </StrictMode>,
);
