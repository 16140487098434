import React, {forwardRef} from "react";
import style from "./dropdown.module.scss";
import {useClickOutside} from "@shared/hooks";
import {type DropdownProps} from "@shared/ui/Dropdown/types";
import {ReactPortal} from "@shared/ui/Portal/ReactPortal";
import {useGetCoordinates} from "@shared/hooks/useGetCoordinates";
import cn from "classnames";

const Dropdown = forwardRef<HTMLDivElement, DropdownProps>(
  (
    {
      excludedElement,
      onClose,
      children,
      position = {horizontal: "right", vertical: "bottom"},
      className,
    }: DropdownProps,
    ref,
  ): React.ReactNode => {
    const clickOutsideRef = useClickOutside<HTMLDivElement>(
      onClose,
      excludedElement,
    );
    const {coordinates} = useGetCoordinates(ref, position);

    return (
      <ReactPortal wrapperId="dropdownContainer">
        {coordinates && (
          <div
            className={cn(
              style.container,
              {[style.transformLeft]: position.horizontal === "right"},
              {[style.transformRight]: position.horizontal === "left"},
            )}
            id="dropdownExcludedElement"
            ref={clickOutsideRef}
            style={coordinates}>
            {children}
          </div>
        )}
      </ReactPortal>
    );
  },
);

Dropdown.displayName = "Dropdown";

export {Dropdown};
