import {type StylesConfig} from "react-select";

export const customStyles: StylesConfig = {
  control: base => ({
    ...base,
    border: "1px solid rgba(211, 218, 224, 1)",
    borderRadius: "8px",
    flexWrap: "nowrap",
  }),
  dropdownIndicator: base => ({
    ...base,
    padding: "8px",
    paddingLeft: "0px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  input: base => ({
    ...base,
    margin: 0,
    padding: 0,
  }),
  singleValue: base => ({
    ...base,
    fontSize: "17px",
  }),
  valueContainer: base => ({
    ...base,
    minWidth: "210px",
    padding: "12px 8px 12px 16px",
  }),
};
