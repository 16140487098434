import {baseApi} from "@shared/api/baseApi";
import {toast} from "react-toastify";
import {type SignUpRequestType, type SignUpResponseType} from "./types";

export const signUpApi = async ({
  email,
  password,
  // phone,
  // name,
}: SignUpRequestType): Promise<SignUpResponseType> => {
  return await baseApi
    .post("v1/auth/sign-up", {
      body: JSON.stringify({email, password}),
      headers: {"Content-type": "application/json"},
    })
    .json();
};
