import React, {useCallback, useState} from "react";
import {ReactPortal} from "@shared/ui/Portal/ReactPortal";
import {RenderAnimation} from "@shared/ui/Animations/MountAnimate";
import {useClickOutside} from "@shared/hooks";
import classes from "./modalCenter.module.scss";
import {type ModalCenterProps} from "@shared/ui/ModalCenter/types";
import {Typography} from "@shared/ui";
import CloseIcon from "@shared/assets/images/Close.svg?react";
import {useNavigate} from "react-router-dom";
import {useCallNoMoreThenN} from "@shared/hooks/useCallNoMoreThenN";

export const ModalCenter = ({
  onClose,
  children,
  title,
  avoidClass,
}: ModalCenterProps): React.ReactNode => {
  const navigate = useNavigate();
  const handleClose = (): void => {
    navigate(-1);
    onClose?.();
  };

  const handleCloseWrapped = useCallNoMoreThenN(handleClose, 1);

  const ref = useClickOutside<HTMLDivElement>([handleCloseWrapped], avoidClass);

  return (
    <ReactPortal wrapperId="modalContainer">
      <RenderAnimation>
        <div className={classes.modal} ref={ref}>
          <div className={classes.modalContainer}>
            <div className={classes.modalWrapper}>
              <div className={classes.modalHeader}>
                <Typography>{title}</Typography>
                <CloseIcon
                  className={classes.modalCloseIcon}
                  onClick={handleClose}
                />
              </div>
              <div className={classes.modalContentContainer}>{children}</div>
            </div>
          </div>
        </div>
      </RenderAnimation>
    </ReactPortal>
  );
};
