import type React from "react";

export enum BodyVariants {
  COMMON = "bodyCommon",
  M = "bodyM",
  SECONDARY = "bodySecondary",
  SECONDARYM = "bodySecondaryM",
  MICROM = "bodyMicroM",
}

export enum HeaderVariants {
  H4 = "header4",
}

export interface TypographyProps
  extends React.HTMLAttributes<HTMLSpanElement | HTMLHeadingElement> {
  children: React.ReactNode;
  v?: BodyVariants | HeaderVariants;
  as?: "span" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p";
}
