import classes from "../styles.module.scss";
import {BodyVariants, Button, Input, Typography} from "@shared/ui";
import React, {type ReactNode} from "react";
import {TextWithStatus} from "@shared/ui/TextWithStatus/TextWithStatus";
import {Statuses} from "@shared/ui/TextWithStatus/types";
import {Notification} from "@shared/ui/Notification/Notification";
import {Flex} from "rebass";

export const AuthToken = (): ReactNode => {
  return (
    <div className={classes.feature}>
      <Flex
        className={classes.featureWrapper}
        flexDirection="column"
        sx={{gap: 3}}>
        <Flex
          alignItems="center"
          flexDirection="row"
          justifyContent="space-between">
          <Typography v={BodyVariants.M}>Токен авторизации</Typography>
          <TextWithStatus indicatorAlign="left" status={Statuses.Error}>
            Не подключен
          </TextWithStatus>
        </Flex>
        <Flex
          alignItems="center"
          className={classes.inputWithButton}
          sx={{gap: 2}}>
          <Input colorScheme="dark" fullWidth />
          <Button className={classes.inputButton}>Получить токен</Button>
        </Flex>
        <Notification title="Токен авторизации требуется чтобы, мы могли отличать настоящие данные от поддельных при пересылке">
          Найдите его в настройках маркетплейса и вставьте в это поле
        </Notification>
      </Flex>
    </div>
  );
};
