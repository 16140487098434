import React, {memo} from "react";
import {type ImageProps} from "./types";

const ImageWrapper = memo((props: ImageProps) => {
  return <img {...props} alt={props.alt} />;
});

ImageWrapper.displayName = "ImageWrapper";

export {ImageWrapper};
