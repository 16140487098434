import {type RefObject, useEffect, useRef} from "react";

/**
 * Custom hook that detects clicks outside of a specified element.
 *
 * @param callback - The callback function to be called when a click outside occurs.
 * @param avoidClass - The class name of elements to be excluded from the click outside detection.
 * @returns A ref object that should be attached to the element to be monitored for clicks outside.
 */

export const useClickOutside = <T extends HTMLElement>(
  callback: VoidFunction | VoidFunction[],
  avoidClass?: string,
): RefObject<T> => {
  const ref = useRef<T>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (ref.current != null && !ref.current.contains(event.target as Node)) {
        if (avoidClass == null) {
          Array.isArray(callback)
            ? callback.forEach(fn => {
                fn();
              })
            : callback();
        } else {
          const isClickOnExcluded =
            event.target instanceof HTMLElement &&
            (event.target.classList.contains(avoidClass) ||
              event.target.id === avoidClass ||
              event.target.closest(`#${avoidClass}`) !== null ||
              event.target.closest(`.${avoidClass}`) !== null);
          if (!isClickOnExcluded) {
            Array.isArray(callback)
              ? callback.forEach(fn => {
                  fn();
                })
              : callback();
          }
        }
      }
    };

    const handleKeyDown = (event: KeyboardEvent): void => {
      if (event.key === "Escape") {
        Array.isArray(callback)
          ? callback.forEach(fn => {
              fn();
            })
          : callback();
      }
    };

    document.addEventListener("click", handleClickOutside, true);
    document.addEventListener("keydown", handleKeyDown, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
      document.removeEventListener("keydown", handleKeyDown, true);
    };
  }, [callback]);

  return ref;
};
