import {type ContentsList} from "@shared/ui/Contents/types";

export const list: ContentsList[] = [
  {
    key: "Дата заказа",
    value: "2 октября 2023",
  },
  {
    key: "Склад",
    value: "Большая Ордынка 40, экспресс",
  },
  {
    key: "Дата отгрузки",
    value: "Еще не отгружен",
  },
  {
    key: "Способ оплаты",
    value: "СБП",
  },
];
