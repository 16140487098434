import style from "@widgets/Orders/ui/OrderByPlaces/ui/orderByPlaces.module.scss";
import React, {memo, useRef, useState} from "react";
import cn from "classnames";
import {BoxedNumber} from "@shared/ui/BoxedNumber/BoxedNumber";
import PhoneImg from "@shared/assets/images/Phone.png";
import {type ProductProps} from "@widgets/Orders/types";
import {Dropdown} from "@shared/ui/Dropdown/Dropdown";
import {toggleBackground} from "@shared/hooks/useBackground";
import {ImageWrapper} from "@shared/ui";

const wrapperHash = {modal: "modalContent", root: "root"};

interface OrderItemProps {
  item: ProductProps;
  backgroundPlacement: "modal" | "root" | null;
}

const actionList = [
  {
    callback: () => {
      console.log("Изменить");
    },
    text: "Изменить",
  },
  {
    callback: () => {
      console.log("Удалить");
    },
    text: "Удалить",
  },
];

export const OrderItem = ({
  item,
  backgroundPlacement,
}: OrderItemProps): React.ReactNode => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const ref = useRef(null);
  const toggleDropdown = (): void => {
    setIsActive(prev => !prev);
    toggleBackground(backgroundPlacement);
  };

  const wrapper = backgroundPlacement && wrapperHash[backgroundPlacement];

  return (
    <>
      <div
        className={cn(style.item, style.borderBottom, {
          [style.dropdown]: isActive,
        })}
        ref={ref}>
        <div className={style.cell}>
          <ImageWrapper height={40} src={PhoneImg} width={40} />
          <div className={style.info}>
            <p className={style.name}>{item.itemName}</p>
            <p className={style.vendorCode}>{item.vendorCode}</p>
          </div>
        </div>
        <div className={style.cell}>{item.count} шт.</div>
        <div className={style.cell}>
          {item.price.toLocaleString("ru-RU", {useGrouping: true})} ₽
        </div>
        <div className={style.cell}>
          <div className={style.actionWrapper} onClick={toggleDropdown}>
            <div className={cn(style.container, style.inactive)}>
              <span className={style.dot} />
              <span className={style.dot} />
              <span className={style.dot} />
            </div>
          </div>
        </div>
      </div>

      {isActive && wrapper && (
        <Dropdown
          excludedElement={style.actionWrapper}
          position={{horizontal: "right", vertical: "bottom"}}
          ref={ref}
          onClose={toggleDropdown}>
          <div>qdqwdqw</div>
          <div>qdqwdqw</div>
        </Dropdown>
      )}
    </>
  );
};

interface OrderByPlacesProps {
  places: ProductProps[][];
  backgroundPlacement: "modal" | "root" | null;
}

const OrderByPlaces = memo(
  ({places, backgroundPlacement}: OrderByPlacesProps): React.ReactNode => {
    return (
      <div className={style.wrapper}>
        {places.map((place, idx) =>
          place.length > 1 ? (
            <React.Fragment key={Math.random()}>
              <div className={style.order}>
                <div className={cn(style.header, style.borderBottom)}>
                  <BoxedNumber>{idx + 1}</BoxedNumber>{" "}
                  <span className={style.text}>место</span>
                </div>
                {place.map(item => (
                  <OrderItem
                    backgroundPlacement={backgroundPlacement}
                    item={item}
                    key={item.price}
                  />
                ))}
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment key={Math.random()}>
              <div className={style.order}>
                {place.map(item => (
                  <OrderItem
                    backgroundPlacement={backgroundPlacement}
                    item={item}
                    key={item.price}
                  />
                ))}
              </div>
            </React.Fragment>
          ),
        )}
      </div>
    );
  },
);

OrderByPlaces.displayName = "OrderByPlaces";

export {OrderByPlaces};
