import {sample} from "effector";
import {
  createWarehouse,
  createWarehouseFx,
  getWarehouses,
} from "@services/Warehouses/Units";

sample({
  clock: createWarehouse,
  target: createWarehouseFx,
});

sample({
  clock: createWarehouseFx.doneData,
  target: getWarehouses,
});
