import style from "../auth.module.scss";
import {BodyVariants, Button, Input, Typography} from "@shared/ui";
import React from "react";
import {FormProvider, type SubmitHandler, useForm} from "react-hook-form";
import {schema, type SignUpFormType} from "@features/auth/signUp/model";
import {PhoneInput} from "@shared/ui/PhoneInput/PhoneInput";
import {useFormErrorNotifications} from "@shared/hooks/useFormErrorNotifications";
import {yupResolver} from "@hookform/resolvers/yup";
import {useUnit} from "effector-react";
import {signUp, signUpSelectors} from "@services/Auth";

export const SignUpForm = (): React.ReactNode => {
  const [isLoading] = useUnit([signUpSelectors.isLoading]);

  const methods = useForm<SignUpFormType>({
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    register,
    formState: {errors},
  } = methods;

  const onSubmit: SubmitHandler<SignUpFormType> = ({password, email}) => {
    signUp({email, password});
  };

  useFormErrorNotifications(errors);

  return (
    <FormProvider {...methods}>
      <form className={style.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={style.formHeader}>
          <Typography v={BodyVariants.M}>Регистрация</Typography>
        </div>
        <div className={style.formFields}>
          <Input
            className={style.formInput}
            colorScheme="dark"
            isError={Boolean(errors.email)}
            label="E-mail"
            {...register("email")}
          />
          <Input
            className={style.formInput}
            colorScheme="dark"
            isError={Boolean(errors.password)}
            label="Пароль"
            type="password"
            {...register("password")}
          />
          <Input
            className={style.formInput}
            colorScheme="dark"
            isError={Boolean(errors.firstName)}
            label="Имя"
            {...register("firstName")}
          />
          <PhoneInput name="phone" />
        </div>
        <div className={style.formFooter}>
          <Button colorScheme="dark" disabled={isLoading} type="submit">
            Далее
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};
