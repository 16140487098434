import {type TextWithStatusProps} from "@shared/ui/TextWithStatus/types";
import {BodyVariants, Typography} from "@shared/ui";
import cn from "classnames";
import classes from "./testWithStatus.module.scss";
import React, {type ReactNode} from "react";

export const TextWithStatus = ({
  children,
  status,
  indicatorAlign = "right",
}: TextWithStatusProps): ReactNode => {
  return (
    <Typography
      className={cn(
        classes.statusText,
        classes[status],
        classes[indicatorAlign],
      )}
      v={BodyVariants.COMMON}>
      {children}
    </Typography>
  );
};
