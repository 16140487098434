import {NavLink, useSearchParams} from "react-router-dom";

import style from "./nav.module.scss";
import {type Link} from "./types";
import React from "react";

export const TabLink = ({
  link,
  img,
  text,
  additional,
}: Link): React.ReactNode => {
  return (
    <li className={style.tabLink}>
      <NavLink to={link}>
        {img}
        <p>
          {text} <span className={style.additional}>{additional}</span>
        </p>
      </NavLink>
    </li>
  );
};
