/// <reference types="vite-plugin-svgr/client" />
import React from "react";
import BoxIcon from "@shared/assets/images/Box.svg?react";
import CellsIcon from "@shared/assets/images/Cells.svg?react";
import CheckIcon from "@shared/assets/images/Check.svg?react";
import LabelIcon from "@shared/assets/images/Label.svg?react";
// import LogoIcon from "@shared/ui/assets/images/Logo.svg?react";
import Settings from "@shared/assets/images/Settings.svg?react";

import Logo from "@shared/assets/images/logo@4x.png";

export const items = [
  {
    img: <BoxIcon />,
    link: "/shipments",
    text: "Отгрузки",
  },
  {
    img: <CellsIcon />,
    link: "/orders?status=pending",
    text: "Заказы",
  },
  {
    img: <LabelIcon />,
    link: "/goods",
    text: "Товары",
  },
  {
    img: <CheckIcon />,
    link: "/supplies",
    text: "Закупки",
  },
];

export const settings = {
  img: <Settings />,
  link: "/settings",
  text: "Настройки",
};

export const logo = {
  img: <img alt="ecomes" height={32} src={Logo} width={125} />,
  text: "",
};
