import classes from "@widgets/ShopSettingsModal/ui/ShopSettingsModal.module.scss";
import {Button, HeaderVariants, Typography} from "@shared/ui";
import {Contents} from "@shared/ui/Contents";
import React, {type ReactNode} from "react";
import YandexLogo from "@shared/assets/images/MarketplaceYandexLogo.svg?react";

export const About = (): ReactNode => {
  const list = [
    {
      key: "Статус",
      value: "Требуется настройка",
    },
    {
      key: "ID",
      value: "150200",
    },
    {
      key: "Модель работы",
      value: "FBY",
    },
  ];

  return (
    <div className={classes.feature}>
      <div className={classes.featureWrapper}>
        <YandexLogo height={64} width={64} />
        <Typography as="h4" v={HeaderVariants.H4}>
          SkyTeck экспресс на ордынке красносельского шоссе Десантника
          Чугунова-Гонгадзе
        </Typography>
        <Button variant="outline">Изменить название на ecomes</Button>
        <div className={classes.itemInfo}>
          <Contents list={list} />
        </div>
        <Button>Приостановить на ecomes</Button>
      </div>
    </div>
  );
};
