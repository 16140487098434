import {object, string} from "yup";
import {type WarehousesFormType} from "@features/warehouses/types";

export const schema = object({
  address: string(),
  comment: string(),
  name: string().required(),
});

export const defaultValues: WarehousesFormType = {
  address: "",
  comment: "",
  name: "",
};
