import React, {lazy} from "react";
import {createBrowserRouter, Navigate} from "react-router-dom";
import {Header} from "@widgets/Header";
import {wrapCreateBrowserRouter} from "@sentry/react";

import {Layout} from "../layout";
import {SignInPage} from "@pages/auth/ui/SignIn";
import {SignUpPage} from "@pages/auth/ui/SignUp";
import {ResetPage} from "@pages/auth/ui/Reset";
import {RouterGuard} from "@app/config/routerGuard";
import {AddShopModal} from "@widgets/ShopAddModal/ui/AddShop";
import {ShopSettingsModal} from "@widgets/ShopSettingsModal/ui/ShopSettingsModal";
import {WarehouseAddModal} from "@widgets/WarehouseAddModal/ui/WarehouseAddModal";
import {SupplyCreateModal} from "@features/supplies/modals/SupplyCreateModal";
import {OrdersInfoModal} from "@widgets/OrdersInfoModal/ui/OrdersInfoModal";

const ShipmentPage = lazy(
  async () =>
    await import("@pages/shipment/ui/Shipment").then(module => ({
      default: module.ShipmentPage,
    })),
);

const ProductsPage = lazy(
  async () =>
    await import("@pages/goods/ui/Goods").then(module => ({
      default: module.GoodsPage,
    })),
);

const OrdersPage = lazy(
  async () =>
    await import("@pages/orders/ui/Orders").then(module => ({
      default: module.OrdersPage,
    })),
);

const SupplyPage = lazy(
  async () =>
    await import("@pages/supply/ui/Supply").then(module => ({
      default: module.SupplyPage,
    })),
);

const SettingsPage = lazy(
  async () =>
    await import("@pages/settings/ui/Settings").then(module => ({
      default: module.SettingsPage,
    })),
);

const ShopsPage = lazy(
  async () =>
    await import("@pages/shops/ui/Shops").then(module => ({
      default: module.ShopsPage,
    })),
);

const WarehousesPage = lazy(
  async () =>
    await import("@pages/warehouses/ui/Warehouses").then(module => ({
      default: module.WarehousesPage,
    })),
);

const ProfilePage = lazy(
  async () =>
    await import("@pages/profile/ui/Profile").then(module => ({
      default: module.ProfilePage,
    })),
);

export const routes = wrapCreateBrowserRouter(createBrowserRouter)([
  {
    element: <SignInPage />,
    path: "login",
  },
  {
    element: <SignUpPage />,
    path: "sign-up",
  },
  {
    element: <ResetPage />,
    path: "restore",
  },
  {
    children: [
      {
        children: [
          {element: <Navigate to="shipments" replace />, index: true},
          {
            children: [{element: <OrdersInfoModal />, path: ":id"}],
            element: <ShipmentPage />,
            path: "shipments/*",
          },
          {
            element: <ProductsPage />,
            path: "goods",
          },
          {
            children: [{element: <OrdersInfoModal />, path: ":id"}],
            element: <OrdersPage />,
            path: "orders/*",
          },
          {
            children: [{element: <SupplyCreateModal />, path: "create-supply"}],
            element: <SupplyPage />,
            path: "supplies/*",
          },
          {
            children: [
              {element: <Navigate to="shops" replace />, index: true},
              {
                /// TODO решить что то с анимацией модалок по роутам
                children: [
                  {element: <AddShopModal />, path: "add-shop"},
                  {element: <ShopSettingsModal />, path: ":id"},
                ],
                element: <ShopsPage />,
                path: "shops/*",
              },
              {
                children: [
                  {
                    element: <WarehouseAddModal />,
                    path: "add-warehouse",
                  },
                ],
                element: <WarehousesPage />,
                path: "warehouses/*",
              },
              {
                element: <ProfilePage />,
                path: "profile",
              },
              {
                element: <div>не найдено</div>,
                path: "*",
              },
            ],
            element: <SettingsPage />,
            path: "settings",
          },
        ],
        element: <Layout headerSlot={<Header />} />,
        errorElement: <div>error</div>,
        path: "/",
      },
    ],
    element: <RouterGuard />,
  },
  {
    element: <div>не найдено</div>,
    path: "*",
  },
]);
