import React, {Suspense} from "react";
import {Outlet, useLocation} from "react-router-dom";
import {Loader} from "@shared/ui";
import {Background} from "@shared/ui/Background/Background";
import {AnimatePresence, motion} from "framer-motion";

interface LayoutProps {
  headerSlot: React.ReactNode;
}

export const Layout = ({headerSlot}: LayoutProps): React.ReactNode => {
  return (
    <div>
      <Background currentPlacement="root" top="0" />
      {headerSlot}
      {/* <AnimatePresence mode="wait"> */}
      {/*  <motion.div */}
      {/*    animate={{opacity: 1, x: 0}} */}
      {/*    exit={{opacity: 0, x: 0}} */}
      {/*    initial={{opacity: 0, x: 0}} */}
      {/*    key={location.pathname}> */}
      <Suspense fallback={<Loader />}>
        <Outlet />
      </Suspense>
      {/*  </motion.div> */}
      {/* </AnimatePresence> */}
    </div>
  );
};
