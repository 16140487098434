import {sample} from "effector";
import {
  $accessToken,
  $signUpError,
  $user,
  navigate,
  signUp,
  signUpFx,
} from "@services/Auth/Units";

sample({
  clock: signUp,
  target: signUpFx,
});

sample({
  clock: signUpFx.failData,
  target: $signUpError,
});

sample({
  clock: signUpFx.doneData,
  fn: ({token}) => token,
  target: [$accessToken],
});

sample({
  clock: signUpFx.doneData,
  fn: ({user}) => user,
  target: [$user, navigate],
});
