import {sample} from "effector";
import {
  $accessToken,
  $user,
  navigate,
  signIn,
  signInFx,
} from "@services/Auth/Units";

sample({
  clock: signInFx.doneData,
  fn: ({user}) => user,
  target: [$user],
});

sample({
  clock: signInFx.doneData,
  fn: ({token}) => token,
  target: [$accessToken, navigate],
});

sample({
  clock: signIn,
  target: signInFx,
});
