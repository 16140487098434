import classes from "../styles.module.scss";
import {BodyVariants, Input, Typography} from "@shared/ui";
import React, {type ReactNode} from "react";
import {TextWithStatus} from "@shared/ui/TextWithStatus/TextWithStatus";
import {Statuses} from "@shared/ui/TextWithStatus/types";
import {Notification} from "@shared/ui/Notification/Notification";
import {Flex} from "rebass";

export const MarketplaceApi = (): ReactNode => {
  return (
    <Flex className={classes.feature}>
      <Flex
        className={classes.featureWrapper}
        flexDirection="column"
        flexGrow={1}
        sx={{gap: 3}}>
        <Flex
          alignItems="center"
          flexDirection="row"
          justifyContent="space-between">
          <Typography v={BodyVariants.M}>
            Связь по API с маркетплейсом
          </Typography>
          <TextWithStatus indicatorAlign="left" status={Statuses.Error}>
            Нет связи
          </TextWithStatus>
        </Flex>
        <div>
          <Input colorScheme="dark" fullWidth />
        </div>
        <Notification title="Вставьте эту ссылку в настройках маркетплейса">
          Пока что связь не обнаружена и мы не можем получать и отправлять
          данные в маркетплейс
        </Notification>
      </Flex>
    </Flex>
  );
};
