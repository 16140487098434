import cn from "classnames";

import style from "./nav.module.scss";
import {TabLink} from "./TabLink";
import {type TabLinksProps} from "./types";
import React from "react";

export const TabLinks = ({
  links,
  bold = false,
  className,
}: TabLinksProps): React.ReactNode => (
  <ul className={cn({[style.bold]: bold}, style.tabLinks, className)}>
    {links.map(link => (
      <TabLink key={link.link} {...link} />
    ))}
  </ul>
);
