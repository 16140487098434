import ky from "ky";
import {toast} from "react-toastify";
import {type RequestError} from "@shared/api/types";

export const baseApi = ky.create({
  hooks: {
    beforeError: [
      async error => {
        const {response} = error;

        if (response?.body && response.status < 500) {
          const result: RequestError = await response.json();
          toast.error(result.error);
        } else {
          toast.error("Внутренняя ошибка сервера");
        }

        return error;
      },
    ],
    beforeRequest: [
      request => {
        request.headers.set("Content-Type", "application/json");
      },
    ],
  },
  prefixUrl: import.meta.env.VITE_BASE_URL,
});

export const baseApiAuthorized = baseApi.extend({
  hooks: {
    beforeRequest: [
      request => {
        request.headers.set(
          "Authorization",
          `Bearer ${JSON.parse(localStorage.getItem("access-token") ?? "")}`,
        );
        request.headers.set("Content-Type", "application/json");
      },
    ],
  },
});
