import {
  $getGoodsError,
  $goodsStore,
  getGoodsByNameOrSkuFx,
  getGoodsFx,
} from "@services/Goods/Units";
import "./Methods/Get/getGoods";

export const getGoodsSelectors = {
  isError: $getGoodsError,
  isLoading: getGoodsFx.pending,
  store: $goodsStore,
};

export const getGoodsByNameOrSkuSelectors = {
  isError: $getGoodsError,
  isLoading: getGoodsByNameOrSkuFx.pending,
  store: $goodsStore,
};
