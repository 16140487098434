import cn from "classnames";
import React, {
  type ChangeEventHandler,
  forwardRef,
  type InputHTMLAttributes,
} from "react";

import style from "./Input.module.scss";
import {BodyVariants, Typography} from "@shared/ui";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  status?: boolean;
  borderless?: boolean;
  colorScheme?: "light" | "dark";
  label?: string;
  fullWidth?: boolean;
  default?: boolean;
  isError?: boolean;
}

const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    className,
    borderless,
    colorScheme = "light",
    style: stylesProp,
    onChange,
    label,
    fullWidth,
    isError,
    ...rest
  } = props;

  const styles = {
    ...stylesProp,
  };

  if (props.width) {
    styles.width = props.width;
  }

  const handleChange: ChangeEventHandler<HTMLInputElement> = event => {
    onChange?.(event);
  };

  return (
    <div className={cn({[style.fullWidth]: fullWidth})}>
      {label && (
        <label className={style.label}>
          <Typography v={BodyVariants.SECONDARYM}>{label}</Typography>
        </label>
      )}
      <input
        className={cn(
          style.input,
          {[style.default]: !borderless},
          {[style.error]: isError},
          {[style.borderless]: borderless},
          {[style.fullWidth]: fullWidth},
          style[colorScheme],
          className,
        )}
        ref={ref}
        style={styles}
        type="text"
        onChange={handleChange}
        {...rest}
      />
    </div>
  );
});

Input.displayName = "Input";

export {Input};
