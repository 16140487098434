import {type InferType, object, string} from "yup";

export const schema = object({
  email: string().email("Ошибочный формат E-mail").required("Введите E-mail"),
  firstName: string(),
  password: string().required("Введите пароль"),
  phone: string(),
});

export type SignUpFormType = InferType<typeof schema>;
