import {motion} from "framer-motion";
import React, {type ReactNode} from "react";
import style from "./container.module.scss";
import {dropIn, opacity} from "./variants";
interface MountAnimateProps {
  children: ReactNode;
}

export const RenderAnimation = ({
  children,
}: MountAnimateProps): React.ReactNode => (
  <>
    <motion.div
      animate="visible"
      className={style.background}
      exit="exit"
      initial="hidden"
      transition={{duration: 0.15, ease: "easeOut"}}
      variants={opacity}
      onClick={e => {
        e.stopPropagation();
      }}
    />
    <motion.div
      animate="visible"
      className={style.container}
      exit="exit"
      initial="hidden"
      transition={{duration: 0.15, ease: "easeOut"}}
      variants={dropIn}
      onClick={e => {
        e.stopPropagation();
      }}>
      {children}
    </motion.div>
  </>
);
