import {flexRender, type RowData} from "@tanstack/react-table";
import React from "react";
import style from "./table.module.scss";
import {type TableProps} from "./types";

declare module "@tanstack/react-table" {
  interface TableMeta<TData extends RowData> {
    updateData: (rowIndex: number, columnId: string, value: unknown) => void;
  }

  interface ColumnMeta<TData extends RowData, TValue> {
    size: {width: string; maxWidth?: string; minWidth?: string};
  }
}

export function Table<TData>({
  table,
  additionalRow,
  newRow,
  className,
}: TableProps<TData>): React.ReactNode {
  return (
    <div className={style.wrapper}>
      <table className={className}>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th key={header.id} style={header.column.columnDef.meta?.size}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map(row => (
            <tr className={style.row} key={row.id}>
              {row.getVisibleCells().map(cell => {
                return (
                  <td key={cell.id} style={cell.column.columnDef.meta?.size}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                );
              })}
            </tr>
          ))}
          {additionalRow}
        </tbody>
      </table>
    </div>
  );
}
