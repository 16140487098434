import React, {EventHandler, type MouseEventHandler, useRef} from "react";
import {Input} from "@shared/ui/Input/Input";
import style from "./editableCell.module.scss";

interface EditableCellProps extends React.HTMLAttributes<HTMLInputElement> {
  id: string;
  value: any;
}
export const EditableCell = ({
  id,
  value,
  className,
  onBlur,
  onChange,
  ...rest
}: EditableCellProps): React.ReactNode => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleFocus: MouseEventHandler<HTMLDivElement> = event => {
    inputRef.current?.focus();
  };

  return (
    <div onClick={handleFocus}>
      <Input
        className={style.input}
        key={id}
        ref={inputRef}
        style={{
          background: "transparent",
          width: "100%",
        }}
        value={value}
        borderless
        onBlur={onBlur}
        onChange={onChange}
      />
    </div>
  );
};
