import {Controller, useFormContext} from "react-hook-form";
import {Input} from "@shared/ui";
import style from "@pages/profile/ui/profile.module.scss";
import React from "react";
import {useMask} from "@react-input/mask";
import {type PhoneInputProps} from "@shared/ui/PhoneInput/types";

export const PhoneInput = ({name}: PhoneInputProps): React.ReactNode => {
  const {control} = useFormContext();

  const inputRef = useMask({
    mask: "+7 (___) ___-__-__",
    replacement: {_: /\d/},
  });

  return (
    <Controller
      control={control}
      name={name}
      render={({field, fieldState}) => (
        <Input
          {...field}
          className={style.formInput}
          colorScheme="dark"
          isError={Boolean(fieldState.error)}
          label="Телефон"
          ref={inputRef}
          fullWidth
        />
      )}
    />
  );
};
