import React from "react";
import {Modal} from "@shared/ui/Modal";
import {useNavigate, useParams} from "react-router-dom";
import classes from "./ShopSettingsModal.module.scss";
import CloseIcon from "@shared/assets/images/Close.svg?react";
import {BodyVariants, Typography} from "@shared/ui";
import {Flex} from "rebass";
import {
  About,
  AuthToken,
  MarketplaceApi,
  OauthToken,
  OrdersSync,
  RemainderSync,
} from "@features/shops/settings";

export const ShopSettingsModal = (): React.ReactNode => {
  const {id} = useParams();

  const navigate = useNavigate();
  const handleClose = (): void => {
    navigate(-1);
  };

  return (
    <Modal className={classes.modal}>
      <div className={classes.header}>
        <div className={classes.title}>Настройки магазина</div>
        <div className={classes.closeIcon} onClick={handleClose}>
          <CloseIcon />
        </div>
      </div>
      <div className={classes.wrapper}>
        <div className={classes.content}>
          <div>
            <Typography
              className={classes.featureTitle}
              v={BodyVariants.MICROM}>
              О магазине
            </Typography>
            <About />
          </div>
          <div>
            <Typography
              as="h4"
              className={classes.featureTitle}
              v={BodyVariants.MICROM}>
              Подключение
            </Typography>
            <Flex flexDirection="column" sx={{gap: 2}}>
              <MarketplaceApi />
              <AuthToken />
              <OauthToken />
            </Flex>
            <Typography
              as="h4"
              className={classes.featureTitle}
              v={BodyVariants.MICROM}>
              Синхронизация
            </Typography>
            <Flex flexDirection="column" sx={{gap: 2}}>
              <RemainderSync />
              <OrdersSync />
            </Flex>
          </div>
        </div>
      </div>
    </Modal>
  );
};
