import "@shared/theme/globals.scss";

import React from "react";
import {RouterProvider} from "react-router-dom";

import style from "./app.module.scss";
import {routes} from "./config/routes";

const App = (): React.ReactNode => {
  return (
    <div className={style.wrapper}>
      <RouterProvider router={routes} />
    </div>
  );
};

export default App;
