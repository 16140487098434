import {createEffect, createEvent, createStore, restore} from "effector";
import {getGoodsApi, getGoodsByNameOrSkuApi} from "@shared/api/goods/getGoods";
import {type GetGoodsType} from "@shared/api/goods/types";

export const getGoods = createEvent();
export const getGoodsByNameOrSku = createEvent<string>();

export const getGoodsFx = createEffect(getGoodsApi);
export const getGoodsByNameOrSkuFx = createEffect(getGoodsByNameOrSkuApi);

export const $goodsStore = createStore<GetGoodsType[]>([]);
export const $getGoodsError = restore(getGoodsFx.failData, null);
