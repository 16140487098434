export const spaceEveryNSymbols = (str: string | number, n: number): string => {
  const format = (str: string | number, n: number): string => {
    str = String(str);
    const s = str.length;
    const chars = str.split("");
    const strWithSpaces = chars.reduceRight((acc, char, i) => {
      const spaceOrNothing = (s - i) % n === 0 ? " " : "";
      return spaceOrNothing + char + acc;
    }, "");

    return strWithSpaces[0] === " " ? strWithSpaces.slice(1) : strWithSpaces;
  };

  return format(str, n);
};

export const spaceEveryNSymbolsNumeric = (
  str: string | number,
  n: number,
): string => {
  str = String(str);
  const removedNonNumeric = str.replace(/\D/g, "");

  return spaceEveryNSymbols(removedNonNumeric, n);
};
