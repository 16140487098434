import style from "./auth.module.scss";
import React from "react";
import {ImageWrapper} from "@shared/ui";
import Logo from "@shared/assets/images/Logo.png";
import {ResetAuthForm} from "@features/auth";

export const ResetPage = (): React.ReactNode => {
  return (
    <div className={style.wrapper}>
      <div className={style.content}>
        <div className={style.header}>
          <ImageWrapper alt="logo" height={48} src={Logo} width={48} />
          <span className={style.logoText}>ecomes</span>
        </div>
        <ResetAuthForm />
      </div>
    </div>
  );
};
