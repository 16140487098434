import cn from "classnames";
import style from "@shared/ui/Button/button.module.scss";

import {type ButtonProps} from "./types";
import React, {type MouseEventHandler} from "react";
import {NavLink} from "react-router-dom";

export const Button = ({
  to = "",
  variant = "contained",
  children,
  fullWidth,
  onClick,
  className,
  colorScheme = "light",
  textAlign = "left",
  ...props
}: ButtonProps): React.ReactNode => {
  const handleClick: MouseEventHandler<HTMLButtonElement> = (e): void => {
    e.stopPropagation();
    if (onClick == null) return;
    onClick();
  };

  if (to)
    return (
      <NavLink
        className={cn(
          style.button,
          {[style.fullwidth]: fullWidth},
          {[style.disabled]: props.disabled},
          style[variant],
          style[colorScheme],
          style[textAlign],
          className,
        )}
        to={to}>
        {children}
      </NavLink>
    );

  return (
    <button
      className={cn(
        style.button,
        {[style.fullwidth]: fullWidth},
        {[style.disabled]: props.disabled},
        style[variant],
        style[colorScheme],
        style[textAlign],
        className,
      )}
      onClick={handleClick}
      {...props}>
      {children}
    </button>
  );
};
