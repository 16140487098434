import classes from "@widgets/ShopSettingsModal/ui/ShopSettingsModal.module.scss";
import {BodyVariants, Button, CustomSelect, Typography} from "@shared/ui";
import React, {type ReactNode} from "react";
import {Flex} from "rebass";
import {Contents} from "@shared/ui/Contents";

export const OrdersSync = (): ReactNode => {
  const list = [{key: "Последняя синхронизация", value: "5 марта 2025"}];

  return (
    <div className={classes.feature}>
      <Flex
        className={classes.featureWrapper}
        flexDirection="column"
        sx={{gap: 3}}>
        <div>
          <Typography v={BodyVariants.M}>Синхронизация заказов</Typography>
        </div>
        <div>
          <Typography v={BodyVariants.COMMON}>
            Загрузятся все заказы за выбранны период для учета статистики в
            ecomes. Все новые заказы будут загружаться автоматически по API
          </Typography>
        </div>
        <div>
          <CustomSelect placeholder="Выбрать период" />
        </div>
        <Flex sx={{gap: 2}}>
          <Button>История</Button>
          <Button colorScheme="dark">Синхронизировать</Button>
        </Flex>
        <div>
          <Contents list={list} />
        </div>
      </Flex>
    </div>
  );
};
