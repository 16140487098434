import {useState} from "react";

type useCallNoMoreThenNProps = (
  fn: (...args: any) => any,
  sec: number,
) => () => void;

export const useCallNoMoreThenN: useCallNoMoreThenNProps = (
  fn,
  sec,
): (() => void) => {
  const [lastCallTime, setLastCallTime] = useState(0);

  return () => {
    const currentTime = Date.now();
    const secondsSinceLastCall = (currentTime - lastCallTime) / 1000;

    if (secondsSinceLastCall >= sec) {
      fn();
      setLastCallTime(currentTime);
    }
  };
};
