import {getCoreRowModel, useReactTable} from "@tanstack/react-table";
import React, {
  type ChangeEventHandler,
  useEffect,
  useRef,
  useState,
} from "react";
import style from "./create.module.scss";
import {
  Button,
  CustomSelect,
  Input,
  Table,
  BoxedNumber,
  Dropdown,
} from "@shared/ui";
import Plus from "@shared/assets/images/PlusIcon.svg?react";
import NoImage from "@shared/assets/images/NoImage.png";
import {useDebouncedCallback} from "use-debounce";
import {toggleBackground} from "@shared/hooks/useBackground";
import cn from "classnames";
import {commentPlaceholder} from "@pages/goods/config/config";
import {columns} from "../config/columns";
import {type SupplyTableProps} from "../types";
import {Modal} from "@shared/ui/Modal";
import {useUnit} from "effector-react";
import {getGoodsByNameOrSku, getGoodsSelectors} from "@services/Goods";
import {type SupplyItemsType} from "@shared/api/supply/types";
import {useNavigate} from "react-router-dom";

const SupplyCreateModal = React.memo(
  ({data}: SupplyTableProps): React.ReactNode => {
    const [tableData, setTableData] = useState([...(data?.items ?? [])]);
    const navigate = useNavigate();

    const hide = (): void => {
      navigate(-1);
    };

    const onSave = (): void => {
      // const items = tableData.reduce(
      //   (acc: CreateSupplyRequestType["goods"], curr) => {
      //     return [
      //       ...acc,
      //       {count: +curr.count, goods_id: curr.goods_id, price: curr.price},
      //     ];
      //   },
      //   [],
      // );
      // // @ts-expect-error
      // createSupply({
      //   comment: null,
      //   document_number: additionalData.supplyNumber,
      //   items,
      //   status: "new",
      // });
    };

    const table = useReactTable<SupplyItemsType>({
      columns,
      data: tableData,
      getCoreRowModel: getCoreRowModel(),
      meta: {
        updateData: (
          rowIndex: number,
          columnId: string,
          value: unknown,
        ): void => {
          setTableData(prevData => {
            const newData = [...prevData];
            newData[rowIndex] = {
              ...newData[rowIndex],
              [columnId]: value,
            };
            return newData;
          });
        },
      },
    });
    const AdditionalRow = (): React.ReactNode => {
      const [isActive, setIsActive] = useState(false);
      const [searchGoodText, setSearchGoodText] = useState("");
      const inputRef = useRef<null | HTMLInputElement>(null);
      const trRef = useRef<null | HTMLTableRowElement>(null);

      const [goods, isLoading, isError] = useUnit([
        getGoodsSelectors.store,
        getGoodsSelectors.isLoading,
        getGoodsSelectors.isError,
      ]);

      const getGoodsByNameOrSkuDebounced = useDebouncedCallback(
        getGoodsByNameOrSku,
        500,
      );

      useEffect(() => {
        searchGoodText && getGoodsByNameOrSkuDebounced(searchGoodText);
      }, [searchGoodText]);

      const toggleIsActive = (): void => {
        setIsActive(prev => !prev);
        toggleBackground("modal");
      };

      useEffect(() => {
        isActive && inputRef.current && inputRef.current?.focus();
      }, [isActive]);

      const handleOnChange: ChangeEventHandler<HTMLInputElement> = e => {
        setSearchGoodText(e.target.value);
      };

      return (
        <>
          {isActive ? (
            <tr
              className={cn(style.addNewGoodRow, {
                [style.addNewGoodRowFocused]: isActive,
              })}
              ref={trRef}>
              <td colSpan={table.getHeaderGroups()[0].headers.length}>
                <div className={style.addNewGoodContainer}>
                  <BoxedNumber>
                    {table.getRowModel().rows.length + 1}
                  </BoxedNumber>
                  <img alt="item" height={40} src={NoImage} width={40} />
                  <Input
                    placeholder="Название или Sku"
                    ref={inputRef}
                    borderless
                    onChange={handleOnChange}
                  />
                  <Button
                    className={style.addNewGoodCancel}
                    onClick={toggleIsActive}>
                    close
                  </Button>
                </div>
                {searchGoodText && goods.length !== 0 && (
                  <Dropdown
                    excludedElement={style.addNewGoodRow}
                    position={{horizontal: "left", vertical: "bottom"}}
                    ref={trRef}
                    onClose={() => {}}>
                    {goods.map(({id, name, sku}) => (
                      <div
                        className={style.dropdownItem}
                        key={sku}
                        onClick={() => {
                          setTableData(prev => [
                            ...prev,
                            {
                              count: 1,
                              goods_id: id,
                              id: "",
                              name,
                              price: 2000,
                              sku,
                            },
                          ]);
                          toggleIsActive();
                        }}>
                        <div className={style.goodName}>{name}</div>
                        <div className={style.goodSku}>{sku}</div>
                      </div>
                    ))}
                  </Dropdown>
                )}
              </td>
            </tr>
          ) : (
            <tr>
              <td colSpan={table.getHeaderGroups()[0].headers.length}>
                <div>
                  <Button
                    className={style.addButton}
                    variant="outline"
                    onClick={toggleIsActive}>
                    <Plus /> Добавить еще
                  </Button>
                </div>
              </td>
            </tr>
          )}
          {/* <tr className={style.expenses}> */}
          {/* <td colSpan={2}>Накладные расходы</td> */}
          {/* <td colSpan={2}>0 ₽</td> */}
          {/* </tr> */}
          {/* <tr> */}
          {/* <td>Итого 2 шт</td> */}
          {/* <td></td> */}
          {/* <td colSpan={2}></td> */}
          {/* </tr> */}
          {/* <tr className={style.test}> */}
          {/* <td>sad</td> */}
          {/* <td>sad</td> */}
          {/* <td>sad</td> */}
          {/* <td>sad</td> */}
          {/* </tr> */}
        </>
      );
    };

    return (
      <Modal avoidClass="dropdownExcludedElement">
        <div className={style.inputsWrapper}>
          <div className={style.inputs}>
            <Input defaultValue="Закупка №" />
            <CustomSelect />
            <Input placeholder={commentPlaceholder} />
          </div>
          <div className={style.actions}>
            <Button onClick={hide}>Отменить</Button>
            <Button colorScheme="dark" onClick={onSave}>
              Сохранить
            </Button>
          </div>
        </div>
        <Table
          additionalRow={<AdditionalRow />}
          className={style.customTable}
          table={table}
        />
      </Modal>
    );
  },
);

SupplyCreateModal.displayName = "SupplyCreateModal";

export {SupplyCreateModal};
