import style from "./typography.module.scss";
import React, {type ReactNode} from "react";
import {BodyVariants, type TypographyProps} from "./types";
import cn from "classnames";

export const Typography = ({
  v = BodyVariants.COMMON,
  children,
  className,
  as: Element = "span",
  ...rest
}: TypographyProps): ReactNode => {
  return (
    <Element className={cn(style[v], className)} {...rest}>
      {children}
    </Element>
  );
};

/// TODO Изменить везде где font в стилях на Typography
