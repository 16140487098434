import style from "./textWithIcon.module.scss";
import {type TextWithIconProps} from "./types";
import React from "react";

export const TextWithIcon = ({
  icon,
  text,
}: TextWithIconProps): React.ReactNode => {
  return (
    <div className={style.TextWithIcon}>
      {icon}
      <span>{text}</span>
    </div>
  );
};
