import {
  $createWarehouseError,
  $getWarehousesError,
  $warehousesStore,
  createWarehouseFx,
  getWarehousesFx,
} from "@services/Warehouses/Units";
import "./Methods/Get/getWarehouses";
import "./Methods/Post/createWarehouse";

export const CommonWarehousesSelectors = {
  warehouses: $warehousesStore,
};

export const createWarehouseSelectors = {
  isError: $createWarehouseError,
  isLoading: createWarehouseFx.pending,
};

export const getWarehousesSelectors = {
  isError: $getWarehousesError,
  isLoading: getWarehousesFx.pending,
};
