import React from "react";
import {type ColumnDef, createColumnHelper} from "@tanstack/react-table";
import style from "../modals/create.module.scss";
import {BoxedNumber} from "@shared/ui/BoxedNumber";
import PhoneImg from "@shared/assets/images/Phone.png";
import {Typography} from "@shared/ui/Typography/Typography";
import {BodyVariants} from "@shared/ui/Typography/types";
import {type FocusEventHandler, type ChangeEventHandler} from "react";
import {spaceEveryNSymbolsNumeric} from "@shared/utils/spaceEveryNSymbols";
import {IncrementableCell} from "@shared/ui/Table/IncrementableCell/IncrementableCell";
import {EditableCell} from "@shared/ui/Table/EditableCell/EditableCell";
import {type SupplyItemsType} from "@shared/api/supply/types";

const getWidth: (width?: string) => {width: string} = width => {
  if (!width) {
    return {
      width: "100%",
    };
  }
  return {
    maxWidth: width,
    minWidth: width,
    width,
  };
};

const columnHelper = createColumnHelper<SupplyItemsType>();

export const columns: Array<ColumnDef<SupplyItemsType, any>> = [
  columnHelper.accessor("goods_id", {
    cell: ({row, getValue, cell, table, column}) => {
      return (
        <div className={style.nameCell} key={cell.id}>
          <BoxedNumber>{row.index + 1}</BoxedNumber>
          <img alt="item" height={40} src={PhoneImg} width={40} />
          <div>
            <div>{getValue()}</div>
            <div className={style.sku}>
              <Typography v={BodyVariants.SECONDARYM}>
                {/* {row.original.sku} */}123123222
              </Typography>
            </div>
          </div>
        </div>
      );
    },
    header: "Товар",
    meta: {
      size: getWidth(),
    },
  }),
  columnHelper.accessor("count", {
    cell: ({getValue, row, column, cell, table}) => {
      const value = getValue();
      const onChange: ChangeEventHandler<HTMLInputElement> = (event): void => {
        let inputValue = event.target.value;
        inputValue = (
          inputValue === "0" || inputValue === "" ? "1" : inputValue
        ).replace(/\s/g, "");
        const formatted = spaceEveryNSymbolsNumeric(inputValue, 3);
        table.options.meta?.updateData(row.index, column.id, formatted);
      };

      const handlePlusClick = (): void => {
        const inputValue = String(getValue());
        const spacesRemoved = inputValue.replace(/\s/g, "");
        if (isNaN(+spacesRemoved)) return;
        const formatted = spaceEveryNSymbolsNumeric(+spacesRemoved + 1, 3);
        table.options.meta?.updateData(row.index, column.id, formatted);
      };

      const handleMinusClick = (): void => {
        const inputValue = String(getValue());
        if (+inputValue === 1) return;
        const spacesRemoved = inputValue.replace(/\s/g, "");
        const isNumber = isNaN(+spacesRemoved);
        if (isNumber) return;
        const formatted = spaceEveryNSymbolsNumeric(+spacesRemoved - 1, 3);
        table.options.meta?.updateData(row.index, column.id, formatted);
      };

      return (
        <IncrementableCell
          handleMinusClick={handleMinusClick}
          handlePlusClick={handlePlusClick}
          id={cell.id}
          value={value}
          onChange={onChange}
        />
      );
    },
    header: "Количество, шт",
    meta: {
      size: getWidth("184px"),
    },
  }),
  columnHelper.accessor("price", {
    cell: ({getValue, row, column, cell, table}) => {
      const value = getValue<number>();

      const onChange: ChangeEventHandler<HTMLInputElement> = (event): void => {
        let inputValue = event.target.value;

        // Запрещение ввода всего кроме чисел и запятой
        if (/[^\d,]/.test(inputValue)) {
          event.preventDefault();
          return;
        }

        const parsedValue = parseFloat(inputValue);

        if (isNaN(parsedValue)) {
          inputValue = "0,00";
        }

        // Вы можете использовать parsedValue, если вам требуется число
        table.options.meta?.updateData(row.index, column.id, inputValue);
      };

      const onBlur: FocusEventHandler<HTMLInputElement> = (event): void => {
        let inputValue = event.target.value;

        // Замена запятой на точку
        inputValue = inputValue.replace(/,/g, ".");

        const parsedValue = parseFloat(inputValue).toFixed(2);

        // Замена точки на запятую
        inputValue = parsedValue.toString().replace(/\./g, ",");

        table.options.meta?.updateData(row.index, column.id, inputValue);
      };

      return (
        <EditableCell
          id={cell.id}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
        />
      );
    },
    enableResizing: false,
    header: "Цена за единицу, ₽",
    id: "price",
    meta: {
      size: getWidth("149px"),
    },
  }),
  columnHelper.accessor(() => "Total", {
    cell: ({row, cell}) => {
      const count = parseInt(String(row.original.count).replace(/\s/g, ""));
      const price = parseFloat(
        String(row.original.price).replaceAll(/\s/g, "").replace(/,/g, "."),
      );

      const res = isNaN(count * price)
        ? ""
        : (count * price).toFixed(2).replace(/\./g, ",");

      return <div>{res}</div>;
    },
    header: "Итого, ₽",
    id: "Total",
    meta: {
      size: getWidth("157px"),
    },
  }),
];
