import {type Orders} from "../../types";

export const orders: Orders = [
  {
    orderNumber: "2434234234",
    partnerName: "LITVINOFF",
    places: [
      [
        {
          count: 2,
          itemName: "iPhone 14 Pro Max 128 gb фиолетовый РСТ, 2022",
          price: 160000,
          vendorCode: 528158,
        },
        {
          count: 1,
          itemName: "iPhone 14 Pro Max 128 gb фиолетовый РСТ, 2022",
          price: 120000,
          vendorCode: 528158,
        },
      ],
      [
        {
          count: 5,
          itemName: "iPhone 14 Pro Max 128 gb фиолетовый РСТ, 2022",
          price: 1213000,
          vendorCode: 522414,
        },
        {
          count: 7,
          itemName: "iPhone 14 Pro Max 128 gb фиолетовый РСТ, 2022",
          price: 12133000,
          vendorCode: 528158,
        },
      ],
    ],
  },
  {
    orderNumber: "24342344123",
    partnerName: "LITVINOFF",
    places: [
      [
        {
          count: 4,
          itemName: "iPhone 13 Pro 128 gb фиолетовый РСТ, 2022",
          price: 115000,
          vendorCode: 5233338,
        },
      ],
    ],
  },
];
