import classes from "@widgets/ShopSettingsModal/ui/ShopSettingsModal.module.scss";
import {BodyVariants, Button, Typography} from "@shared/ui";
import React, {type ReactNode} from "react";
import {Flex} from "rebass";
import {Contents} from "@shared/ui/Contents";

export const RemainderSync = (): ReactNode => {
  const list = [{key: "Последняя синхронизация", value: "5 марта 2025"}];

  return (
    <div className={classes.feature}>
      <Flex
        className={classes.featureWrapper}
        flexDirection="column"
        sx={{gap: 3}}>
        <div>
          <Typography v={BodyVariants.M}>Синхронизация остатков</Typography>
        </div>
        <div>
          <Typography v={BodyVariants.COMMON}>
            Будет создана закупка по полученному количеству остатков c
            маркетплейса, если будет найдена разница с текущими остатками то
            добавится только она.
          </Typography>
        </div>
        <Flex sx={{gap: 2}}>
          <Button>История</Button>
          <Button colorScheme="dark">Синхронизировать</Button>
        </Flex>
        <div>
          <Contents list={list} />
        </div>
      </Flex>
    </div>
  );
};
