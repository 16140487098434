import {createDomain, createEvent, restore} from "effector";
import {persist} from "effector-storage/local";
import {type components} from "@shared/api/schemas/api";
import {
  signInApi,
  type SignInRequestType,
  signUpApi,
  type SignUpRequestType,
} from "@shared/api/auth";

export enum TOKENS {
  ACCESS = "accessToken",
}

export const authDomain = createDomain("authDomain");

export const $accessToken = authDomain.store<string | null>(null);

persist({
  key: "access-token",
  store: $accessToken,
});

export const $user = authDomain.store<components["schemas"]["User"] | null>(
  null,
);

persist({
  key: "user",
  store: $user,
});

export const $isAuthorized = $accessToken.map(Boolean);

// export const $user = createStore<components["schemas"]["User"] | null>(null);
export const navigate = createEvent();
navigate.watch(() => {
  location.href = "/";
});

export const signUp = authDomain.event<SignUpRequestType>();
export const signIn = authDomain.event<SignInRequestType>();
export const signUpFx = authDomain.effect(signUpApi);
export const signInFx = authDomain.effect(signInApi);
export const $signInError = restore(signInFx.failData, null);
export const $signUpError = restore(signUpFx.failData, null);
