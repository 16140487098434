import {Navigate, Outlet} from "react-router-dom";
import React from "react";
import {useUnit} from "effector-react";
import {authSelectors} from "@services/Auth";

export const RouterGuard = (): React.ReactNode => {
  const isAuthorized = useUnit(authSelectors.isAuth);

  if (isAuthorized) {
    return <Outlet />;
  }

  return <Navigate to="/login" replace />;
};
