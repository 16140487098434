import {TabLinks} from "@shared/ui/TabLinks/TabLinks";
import {items, logo, settings} from "@widgets/Header/config";

import style from "./header.module.scss";
import React from "react";

export const Header = (): React.ReactNode => {
  return (
    <div className={style.header}>
      <div className={style.logo}>
        {logo.img}
        <span>{logo.text}</span>
      </div>
      <nav className={style.nav}>
        <TabLinks links={items} bold />
      </nav>
      <div className={style.actions}>
        <TabLinks links={[settings]} bold />
      </div>
    </div>
  );
};
