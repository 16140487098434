import {baseApiAuthorized} from "@shared/api/baseApi";
import {type WarehouseType} from "@shared/api/warehouses/types";

export const getWarehousesApi = async (): Promise<WarehouseType[]> => {
  return await baseApiAuthorized
    .get("v1/warehouses", {
      headers: {"Content-type": "application/json"},
    })
    .json();
};
