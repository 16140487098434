import {type FieldErrors} from "react-hook-form/dist/types/errors";
import {useEffect} from "react";
import _ from "underscore";
import {toast} from "react-toastify";

export const useFormErrorNotifications = (errors: FieldErrors<any>): void => {
  useEffect(() => {
    if (_.isEmpty(errors)) return;
    _.each(
      _.values(errors),
      error => error && _.isString(error.message) && toast.error(error.message),
    );
  }, [errors]);
};
