import {FormProvider, type SubmitHandler, useForm} from "react-hook-form";
import style from "./warehouses.module.scss";
import React from "react";
import {type WarehousesFormType} from "@features/warehouses/types";
import {Button, Input} from "@shared/ui";

import {defaultValues} from "@features/warehouses/model";
import {useUnit} from "effector-react";
import {createWarehouseSelectors, createWarehouse} from "@services/Warehouses";

export const WarehousesForm = (): React.ReactNode => {
  const methods = useForm<WarehousesFormType>({
    defaultValues,
  });

  const [isLoading, isError] = useUnit([
    createWarehouseSelectors.isLoading,
    createWarehouseSelectors.isError,
  ]);

  const {handleSubmit, register, formState} = methods;

  const onSubmit: SubmitHandler<WarehousesFormType> = (data, e): void => {
    e?.stopPropagation();
    createWarehouse({name: data.name});
  };

  return (
    <FormProvider {...methods}>
      <div className={style.container}>
        <form className={style.formFields} onSubmit={handleSubmit(onSubmit)}>
          <Input
            {...register("name")}
            className={style.formInput}
            colorScheme="dark"
            label="Название склада"
            name="name"
            // value={user?.email}
            fullWidth
          />
          <Input
            {...register("address")}
            className={style.formInput}
            colorScheme="dark"
            isError={Boolean(formState.errors.address)}
            label="Адрес"
            name="address"
            fullWidth
          />
          <Input
            {...register("comment")}
            className={style.formInput}
            colorScheme="dark"
            isError={Boolean(formState.errors.comment)}
            label="Комментарий"
            name="comment"
            fullWidth
          />
          <Button
            disabled={isLoading}
            textAlign="center"
            type="submit"
            fullWidth>
            Создать склад
          </Button>
        </form>
      </div>
    </FormProvider>
  );
};
