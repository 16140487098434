import React, {type MouseEventHandler, useRef, useState} from "react";
import Minus from "@shared/assets/images/minus.svg?react";
import Plus from "@shared/assets/images/plus.svg?react";
import {IconButton} from "@shared/ui/IconButton/IconButton";
import style from "./incrementableCell.module.scss";
import {Input} from "@shared/ui/Input/Input";

interface IncrementableCellProps
  extends React.HTMLAttributes<HTMLInputElement> {
  id: string;
  value: string;
  handlePlusClick: () => void;
  handleMinusClick: () => void;
}
export const IncrementableCell = ({
  id,
  onChange,
  handlePlusClick,
  handleMinusClick,
  value,
  ...rest
}: IncrementableCellProps): React.ReactNode => {
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleFocusOnInput: MouseEventHandler<HTMLDivElement> = event => {
    if (event.currentTarget === event.target) {
      inputRef.current?.focus();
    }
  };

  const onBlur = (): void => {
    setIsFocused(false);
  };

  const onFocus = (): void => {
    setIsFocused(true);
  };

  return (
    <div className={style.container} onClick={handleFocusOnInput}>
      {!isFocused && (
        <IconButton
          className={style.iconButton}
          icon={<Minus />}
          onClick={handleMinusClick}
        />
      )}
      <Input
        className={style.input}
        ref={inputRef}
        style={{
          background: "transparent",
          width: `64px`,
        }}
        value={value}
        borderless
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        {...rest}
      />
      {!isFocused && (
        <IconButton
          className={style.iconButton}
          icon={<Plus />}
          onClick={handlePlusClick}
        />
      )}
    </div>
  );
};
