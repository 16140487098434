import {ModalCenter} from "@shared/ui/ModalCenter/ModalCenter";
import classes from "./addShop.module.scss";
import {
  BodyVariants,
  Button,
  HeaderVariants,
  Input,
  Typography,
} from "@shared/ui";
import React, {type ChangeEventHandler, useEffect} from "react";
import {type ShopItemProps} from "../types";

import YandexLogo from "@shared/assets/images/MarketplaceYandexLogo.svg?react";
import PointerRight from "@shared/assets/images/PointerRight.svg?react";
import Key from "@shared/assets/images/Key.svg?react";

import {shops} from "@widgets/ShopAddModal/ui/config";
import {useNavigate} from "react-router-dom";

const ShopItem = ({text, Logo, ...props}: ShopItemProps): React.ReactNode => {
  return (
    <div className={classes.shopItem} {...props}>
      {<Logo />}
      <Typography className={classes.shopItemText}>{text}</Typography>
      <Typography
        className={classes.shopItemSoonText}
        v={BodyVariants.SECONDARY}>
        Скоро
      </Typography>
      <PointerRight />
    </div>
  );
};

export const AddShopModal = (): React.ReactNode => {
  const [selectedShop, setSelectedShop] = React.useState<string>("");
  const [token, setToken] = React.useState<string>("");
  const navigate = useNavigate();

  const selectYandex = (): void => {
    setSelectedShop("yandex");
  };

  const handleChangeToken: ChangeEventHandler<HTMLInputElement> = event => {
    setToken(event.target.value);
  };

  const onClose = (): void => {
    navigate("/settings/shops");
  };

  useEffect(() => {
    return () => {
      console.log("unmounted");
    };
  }, []);

  return (
    <ModalCenter title="Подключение магазина" onClose={onClose}>
      {selectedShop === "yandex" && (
        <div className={classes.addShop}>
          <div className={classes.addShopContent}>
            <YandexLogo height={64} width={64} />
            <Typography as="h4" v={HeaderVariants.H4}>
              Требуется OAuth токен
            </Typography>
            <Typography v={BodyVariants.COMMON}>
              Перейдите в Яндекс.Маркет и найдите OAuth токен для подключения
              магазина
            </Typography>
            <Input
              colorScheme="dark"
              placeholder="Введите скопированный токен"
              fullWidth
              onChange={handleChangeToken}
            />
          </div>
          <div className={classes.addShopFooter}>
            <Button>
              <Key />
              Получить токен
            </Button>
            <Button colorScheme="dark" disabled={!token}>
              Подключить
            </Button>
          </div>
        </div>
      )}
      {!selectedShop && (
        <div className={classes.shopList}>
          {shops.map(({logo, text}, index) => (
            <ShopItem
              key={index}
              Logo={logo}
              text={text}
              onClick={selectYandex}
            />
          ))}
        </div>
      )}
    </ModalCenter>
  );
};
