import {toast} from "react-toastify";
import {baseApiAuthorized} from "@shared/api/baseApi";
import {
  type CreateWarehouseRequestType,
  type WarehouseType,
} from "@shared/api/warehouses/types";

export const createWarehousesApi = async (
  body: CreateWarehouseRequestType,
): Promise<WarehouseType> => {
  const bodyJson = JSON.stringify(body);
  return await toast.promise(
    baseApiAuthorized
      .post("v1/warehouses", {
        body: bodyJson,
        headers: {"Content-type": "application/json"},
      })
      .json(),
    {
      error: "Ошибка",
      pending: "Создаем склад",
      success: "Склад успешно создан",
    },
  );
};
