import style from "../auth.module.scss";
import {BodyVariants, Button, Input, Typography} from "@shared/ui";
import React from "react";
import {type SubmitHandler, useForm} from "react-hook-form";
import {useNavigate, useLocation} from "react-router-dom";

interface FormFields {
  email: string;
}

export const ResetAuthForm = (): React.ReactNode => {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    register,
    formState: {errors},
    handleSubmit,
  } = useForm<FormFields>();

  const onSubmit: SubmitHandler<FormFields> = data => {
    console.log(data);
  };

  const navigateTo = (): void => {
    if (location.key === "default") {
      navigate("/");
    } else {
      navigate(-1);
    }
  };

  return (
    <form className={style.form} onSubmit={handleSubmit(onSubmit)}>
      <div className={style.formHeader}>
        <Typography v={BodyVariants.M}>Вход</Typography>
      </div>
      <div className={style.formFields}>
        <Input
          className={style.formInput}
          colorScheme="dark"
          label="Ваш E-mail при регистрации"
          type="email"
          {...register("email")}
        />
        {errors.email && <div>Поле обязательно</div>}
      </div>
      <div className={style.formFooter}>
        <Button onClick={navigateTo}>Назад</Button>
        <Button colorScheme="dark" type="submit">
          Восстановить
        </Button>
      </div>
    </form>
  );
};
