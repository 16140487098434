import {baseApiAuthorized} from "@shared/api/baseApi";
import {type GetGoodsType} from "@shared/api/goods/types";

export const getGoodsApi = async (): Promise<GetGoodsType[]> => {
  return await baseApiAuthorized
    .get("v1/goods", {headers: {"Content-type": "application/json"}})
    .json();
};

export const getGoodsByNameOrSkuApi = async (
  nameOrSku: string,
): Promise<GetGoodsType[]> => {
  return await baseApiAuthorized
    .get("v1/goods", {
      headers: {"Content-type": "application/json"},
      searchParams: {q: nameOrSku},
    })
    .json();
};
