import classes from "@widgets/ShopSettingsModal/ui/ShopSettingsModal.module.scss";
import {BodyVariants, Button, Input, Typography} from "@shared/ui";
import {TextWithStatus} from "@shared/ui/TextWithStatus/TextWithStatus";
import {Statuses} from "@shared/ui/TextWithStatus/types";
import React, {type ReactNode} from "react";
import {Flex} from "rebass";
import {Contents} from "@shared/ui/Contents";

export const OauthToken = (): ReactNode => {
  const list = [{key: "Дата истечения", value: "5 марта 2025"}];

  return (
    <div className={classes.feature}>
      <Flex
        className={classes.featureWrapper}
        flexDirection="column"
        sx={{gap: 3}}>
        <Flex justifyContent="space-between" sx={{gap: 3}}>
          <Typography v={BodyVariants.M}>OAuth токен</Typography>
          <TextWithStatus indicatorAlign="left" status={Statuses.Success}>
            Подключен
          </TextWithStatus>
        </Flex>
        <Flex className={classes.inputWithButton} sx={{gap: 2}}>
          <Input colorScheme="dark" fullWidth />
          <Button className={classes.inputButton}>Получить токен</Button>
        </Flex>
        <div>
          <Contents list={list} />
        </div>
      </Flex>
    </div>
  );
};
